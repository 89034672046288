var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"600","max-height":"600"}},[_c('v-card-title',[_vm._v(" Pet List - "+_vm._s(this.petList.length)+" - will need : - "+_vm._s(this.petDiamonds)+" diamonds ")]),_c('v-dialog',{attrs:{"max-width":"900px","scrollable":""},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(this.$get(_vm.selectedPet,'name'))+" ")]),_c('v-data-table',{staticClass:"elevation-1 petList",attrs:{"headers":_vm.headersProgressionDetail,"items":_vm.listPetProgression,"hide-default-footer":"","options":{itemsPerPage:200},"search":_vm.search,"show-group-by":""},scopedSlots:_vm._u([{key:"item.tap",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tap)+" hits ")]}},{key:"item.regeneration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.regeneration / 60)+" mn ")]}},{key:"item.damage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.damage)+" % ")]}},{key:"item.skillValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.skillValue)+" % ")]}},{key:"item.passiveSkillValue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.passiveSkillValue)+" % ")]}}])})],1)],1),_c('v-text-field',{attrs:{"type":"number","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=_vm._n($$v)},expression:"search"}}),_c('v-card',{staticClass:"overflow-y-auto",attrs:{"max-height":"400"}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 petList",attrs:{"headers":_vm.headers,"items":_vm.petList,"hide-default-footer":"","options":{itemsPerPage:200},"search":_vm.search,"show-group-by":""},on:{"click:row":_vm.didClickOnRow},scopedSlots:_vm._u([{key:"item.petBaseDamage",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.petBaseDamage},on:{"update:returnValue":function($event){return _vm.$set(item, "petBaseDamage", $event)},"update:return-value":function($event){return _vm.$set(item, "petBaseDamage", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Edit","single-line":"","autofocus":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.petBaseDamage),callback:function ($$v) {_vm.$set(item, "petBaseDamage", _vm._n($$v))},expression:"item.petBaseDamage"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.petBaseDamage)+" ")])]}},{key:"item.petDamIncPerLvT1",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.petDamIncPerLvT1},on:{"update:returnValue":function($event){return _vm.$set(item, "petDamIncPerLvT1", $event)},"update:return-value":function($event){return _vm.$set(item, "petDamIncPerLvT1", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Edit","single-line":"","autofocus":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.petDamIncPerLvT1),callback:function ($$v) {_vm.$set(item, "petDamIncPerLvT1", _vm._n($$v))},expression:"item.petDamIncPerLvT1"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.petDamIncPerLvT1)+" ")])]}},{key:"item.petDamIncPerLvT2",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.petDamIncPerLvT2},on:{"update:returnValue":function($event){return _vm.$set(item, "petDamIncPerLvT2", $event)},"update:return-value":function($event){return _vm.$set(item, "petDamIncPerLvT2", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Edit","single-line":"","autofocus":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.petDamIncPerLvT2),callback:function ($$v) {_vm.$set(item, "petDamIncPerLvT2", _vm._n($$v))},expression:"item.petDamIncPerLvT2"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.petDamIncPerLvT2)+" ")])]}},{key:"item.petDamIncPerLvT3",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.petDamIncPerLvT3},on:{"update:returnValue":function($event){return _vm.$set(item, "petDamIncPerLvT3", $event)},"update:return-value":function($event){return _vm.$set(item, "petDamIncPerLvT3", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Edit","single-line":"","autofocus":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.petDamIncPerLvT3),callback:function ($$v) {_vm.$set(item, "petDamIncPerLvT3", _vm._n($$v))},expression:"item.petDamIncPerLvT3"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.petDamIncPerLvT3)+" ")])]}},{key:"item.petBonusType",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.petBonusType},on:{"update:returnValue":function($event){return _vm.$set(item, "petBonusType", $event)},"update:return-value":function($event){return _vm.$set(item, "petBonusType", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-select',{attrs:{"items":_vm.petBonusTypeSelect,"item-text":"text","item-value":"value","label":"Select","single-line":""},model:{value:(item.petBonusType),callback:function ($$v) {_vm.$set(item, "petBonusType", $$v)},expression:"item.petBonusType"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.petBonusType)+" ")])]}},{key:"item.petBoIncPerLv",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.petBoIncPerLv},on:{"update:returnValue":function($event){return _vm.$set(item, "petBoIncPerLv", $event)},"update:return-value":function($event){return _vm.$set(item, "petBoIncPerLv", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Edit","single-line":"","autofocus":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.petBoIncPerLv),callback:function ($$v) {_vm.$set(item, "petBoIncPerLv", _vm._n($$v))},expression:"item.petBoIncPerLv"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.petBoIncPerLv)+" ")])]}},{key:"item.petBaseBonus",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.petBaseBonus},on:{"update:returnValue":function($event){return _vm.$set(item, "petBaseBonus", $event)},"update:return-value":function($event){return _vm.$set(item, "petBaseBonus", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Edit","single-line":"","autofocus":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(item.petBaseBonus),callback:function ($$v) {_vm.$set(item, "petBaseBonus", _vm._n($$v))},expression:"item.petBaseBonus"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.petBaseBonus)+" ")])]}}])})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.dispatchClose()}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.dispatchSave()}}},[_vm._v("Save")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }