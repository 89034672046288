<template >

  <v-app>

    <div >

      <v-row
          align="center"
          justify="space-around"

      >
        <v-btn depressed
               v-on:click="showSupport=true"
        >
          Supports
        </v-btn>

        <v-btn depressed
               v-on:click="showTimeCharts=true"
        >
          Playing Time
        </v-btn>

        <v-btn depressed
               v-on:click="showAchievements=true"
        >
          Achievements
        </v-btn>

        <v-btn depressed
               v-on:click="showQuests=true"
        >
          Quests
        </v-btn>



        <v-btn depressed
               v-on:click="showPets=true"
        >
          Pets
        </v-btn>


        <v-dialog
            v-model="showSupport"
            max-width="900px"
            scrollable
            @click:outside="showSupport=false"
        >

          <supportSkillViewer :pSkillList="this.$store.state.skillList" :should-init="showSupport" @close="showSupport=false"/>


        </v-dialog>

        <v-dialog
            v-model="showTimeCharts"
            max-width="900px"
            scrollable
            @click:outside="showSupport=false"
        >

          <v-card
              min-height="600"
              max-height="600"
          >
            <v-card-title>
              Playing Time
            </v-card-title>


            <playingTime />

          </v-card>



        </v-dialog>




        <v-dialog
            v-model="showPets"
            max-width="900px"
            scrollable
            @click:outside="showPets=false"
        >

          <petListView   :pBalance="this.balanceBuilder" :pPetList="this.$store.state.petsData" :should-init="showPets" @close="showPets=false"/>


        </v-dialog>

        <v-dialog
            v-model="showAchievements"
            max-width="900px"
            scrollable
            @click:outside="showAchievements=false"
        >

          <achievementListView :pAchievementList="this.$store.state.achievementList" :should-init="showAchievements" @close="showAchievements=false"/>


        </v-dialog>

        <v-dialog
            v-model="showQuests"
            max-width="900px"
            scrollable
            @click:outside="showQuests=false"
        >

          <questListView :pQuestList="this.$store.state.questList" :should-init="showQuests" @close="showQuests=false"/>


        </v-dialog>

        <v-dialog
            v-model="showDetailDiamonds"
            max-width="900px"
            scrollable
            @click:outside="showDetailDiamonds=false"
        >

          <diamondDetailView :pDiamondList="this.diamondDetailList" :pHeaders="this.headers" :should-init="showDetailDiamonds" @close="showDetailDiamonds=false"/>


        </v-dialog>



      </v-row>


      <v-expansion-panels  accordion multiple>

        <v-expansion-panel>
          <v-expansion-panel-header>General</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actions"
                :items-per-page="5"
                class="elevation-1"
                hide-default-footer
            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>KPIs</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsKpis"
                :items-per-page="10"
                class="elevation-1"
                hide-default-footer
            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Diamands</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsDiamonds"
                :items-per-page="10"
                class="elevation-1"
                @click:row="doClickOnDetailDiamonds"
                hide-default-footer
            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Gains</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table

                :headers="headers"
                :items="actionsResources"
                :items-per-page="5"
                class="elevation-1"
                hide-default-footer


            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>R�partitions des gains</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsAllocations"
                :items-per-page="5"
                class="elevation-1"
                hide-default-footer

            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>

        <!--            <v-expansion-panel>-->
        <!--                <v-expansion-panel-header>Gains de diamands</v-expansion-panel-header>-->
        <!--                <v-expansion-panel-content>-->
        <!--                    <v-data-table-->
        <!--                            :headers="headers"-->
        <!--                            :items="diamondsAllocations"-->
        <!--                            :items-per-page="5"-->
        <!--                            class="elevation-1"-->
        <!--                            hide-default-footer-->


        <!--                    ></v-data-table>-->

        <!--                </v-expansion-panel-content>-->
        <!--            </v-expansion-panel>-->


        <v-expansion-panel>
          <v-expansion-panel-header>Actions des supports</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsSupport"
                :items-per-page="5"
                class="elevation-1"
                hide-default-footer

            >





            </v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Statistiques des supports</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="supportLevelUp"
                :items-per-page="5"
                class="elevation-1"
                hide-default-footer

            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header>PETS</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsPet"
                :items-per-page="5"
                class="elevation-1"
                hide-default-footer
            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>BONUS</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsBonus"
                :items-per-page="40"
                class="elevation-1"
                hide-default-footer
            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header>BONUS PASSIVE SUPPORT</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsBonusSupport"
                :items-per-page="40"
                class="elevation-1"
                hide-default-footer
            >

              <template  v-for="slot in slots" v-slot:[renderSlotName(slot)]="{value}">

                <v-edit-dialog
                    :key="slot"
                    :return-value.sync="value"

                >
                  {{ renderCellPetPassive(value.supportCumulatedAllDamagePercentage)}}
                  <template v-slot:input>
                    <v-data-table
                        :headers=supportDetailHeaders
                        :items=value.detailSkills
                        :items-per-page="40"
                        class="elevation-1"
                        hide-default-footer
                    >

                      <template v-slot:item.percent="{item}">

                        {{renderCellSupportPercentSkill(item.percent)}}
                      </template>

                      <template v-slot:item.level="{item}">
                        {{renderCellSupportLevel(item.level)}}
                      </template>

                    </v-data-table>
                  </template>
                </v-edit-dialog>
              </template>

            </v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>


        <v-expansion-panel>
          <v-expansion-panel-header>BONUS PASSIVE PET</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsBonusPassivePet"
                :items-per-page="40"
                class="elevation-1"
                hide-default-footer
            >
              <!--                v-for="slot in item.keys" -->
              <template  v-for="slot in slots" v-slot:[renderSlotName(slot)]="{value}">

                <v-edit-dialog
                    :key="slot"
                    :return-value.sync="value"

                >
                  {{ renderCellPetPassive(value.totalPassiveSkill)}}
                  <template v-slot:input>
                    <v-data-table
                        :headers=petDetailHeaders
                        :items=value.listDetail
                        :items-per-page="40"
                        class="elevation-1"
                        hide-default-footer
                    >
                    </v-data-table>
                  </template>
                </v-edit-dialog>
              </template>


            </v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>Level UP</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsLevelUp"
                :items-per-page="5"
                class="elevation-1"
                hide-default-footer

            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Gameplay</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
                :headers="headers"
                :items="actionsGameplay"
                :items-per-page="50"
                class="elevation-1"
                hide-default-footer

            ></v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>



      </v-expansion-panels>


      <FooterLayout></FooterLayout>

    </div>

    <!--            <supportData-->

    <!--                        :modal="showForm"-->
    <!--                        :skillList="skillList"-->
    <!--                        @closeModal="doCloseForm()"-->

    <!--                        />-->


    <!--            <v-footer app>-->
    <!--                &lt;!&ndash; &ndash;&gt;-->

    <!--                <v-row>-->
    <!--                    <v-col align="right">-->
    <!--                        <router-link  style="text-decoration: none; color: inherit;"-->
    <!--                                      to='/'>-->
    <!--                            <v-icon-->
    <!--                                    color=red>-->
    <!--                                mdi-exit-to-app-->
    <!--                            </v-icon>-->
    <!--                        </router-link>-->
    <!--                    </v-col>-->
    <!--                </v-row>-->


    <!--            </v-footer>-->

  </v-app>

</template>
<script>

//import BalanceBuilder from "./BalanceBuilder";

//import supportData from './components/ModalSupportData.vue'
//import supportLoad from './support-load.js'

import supportSkillViewer from './components/SupportSkillViewer.vue';
import petListView from './components/PetList.vue';
import achievementListView from './components/AchivementList.vue';
import questListView from './components/QuestList.vue';
import diamondDetailView  from './components/DiamondsDetail.vue';
import KAchievement from './constants/AchievementIdConstants';

import FooterLayout from "./components/commons/FooterLayout.vue";

let SMPNum = require("./SMPNum");



//console.log('il faut �crire quelque chose');

//test
//actionsGameplay.push(balanceBuilder.getRow(balanceBuilder.listPossibleLevelUp,true));
//actionsGameplay.push(balanceBuilder.buildDmgHero2());
//actionsGameplay.push(balanceBuilder.buildHeroTapToKillGhostSolo());

//actionsGameplay.push(balanceBuilder.buildHeroTapToKillGhostSoloLevelUp());

//import skillList from './skill-list.js'


export default {

  data() {
    return {
      showSupport: false,
      showAchievements:false,
      showQuests: false,
      showPets:false,
      showTimeCharts:false,
      showDetailDiamonds: false,
      // headers: headers,
      // actions: actions,
      // actionsGameplay: actionsGameplay,
      // actionsResources: actionsResources,
      // actionsAllocations: actionsAllocations,
      // actionsLevelUp: actionsLevelUp,
      // actionsSupport: actionsSupport,
      // supportLevelUp:supportStats,
      balanceBuilder: [],
      headers: [],
      actions: [],
      actionsKpis : [],
      actionsDiamonds : [],
      actionsGameplay: [],
      actionsResources: [],
      actionsAllocations: [],
      actionsLevelUp: [],
      actionsSupport: [],
      supportLevelUp: [],
      diamondsAllocations: [],
      actionsPet: [],
      actionsBonus : [],
      actionsBonusPassivePet : [],
      actionsBonusSupport : [],
      diamondDetailList : [],

      supportDetailHeaders: [
        {
          text: 'support',
          align: 'start',
          sortable: false,
          value: 'support',
        },
        {
          text: 'level',
          align: 'start',
          sortable: false,
          value: 'level',
        },
        {
          text: 'skillName',
          align: 'start',
          sortable: false,
          value: 'skillName',
        },
        {
          text: 'percent',
          align: 'start',
          sortable: false,
          value: 'percent',
        },
      ],
      petDetailHeaders:
          [
            {
              text: 'petName',
              align: 'start',
              sortable: false,
              value: 'petName',
            },
            {
              text: 'maxLevel',
              align: 'start',
              sortable: false,
              value: 'maxLevel',
            },
            {
              text: 'skillValue',
              align: 'start',
              sortable: false,
              value: 'skillValue',
            }
          ],

      slots: [],

      showForm : false
    }
  },

  //test
  created: async function () {
    //console.log('BALANCE VUE: balance VUE CREATED');
    //console.log('BALANCE VUE: did finished balance home vue supportLoad.loadSkillData');

    let temp  = [1, 10, 40, 100, 150, 300, 500, 999, 1500, 2000, 3000, 4000, 5000, 8000, 10000, 20000, 50000];

    temp = this.$store.state.balanceBuilder.levels;
    temp.forEach(lvl => {
      this.slots.push('lvl'+lvl);
    });

    //this.balanceBuilder();
    this.initBalanceVue();

  },


  watch: {
    skillData: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.localModel.skillNameFr = this.skillData.skillNameFr;
      this.localModel.skillNameEn = this.skillData.skillNameEn;
      this.localModel.skillNameKh = this.skillData.skillNameKh;
    },

    pBalanceBuilder:  {
      handler(newVal,oldVal) {
        //console.log('BALANCE watch balance builder')
        console.log(newVal);
        console.log(oldVal);
        this.initBalanceVue();
      },
      deep: true
    }
  },

  methods : {


    renderCellPetPassive : function(value) {
      if (value <= 0) {
        return 'n/a';
      }else {
        return value + '%';
      }
    },


    renderCellSupportPercentSkill : function(percent) {

      if (percent.toFixed) {
        return percent.toFixed(2) + '%';
      }else {
        return percent + '%';
      }

    },

    renderCellSupportLevel : function(supportLevel) {
      let smpNumLevel = new SMPNum(supportLevel);
      return smpNumLevel.ToReadableAlphabetV2();

    },

    renderSlotName : function(slot) {
      return `item.${slot}`;
    },

    doClickOnDetailDiamonds : function() {
      this.showDetailDiamonds = true;
      //console.log('CLICK ON DIAMAOND SHOULD OPEN ANOTHER POPUP WITH TABLE THERE');
    },

    doCloseForm: function() {
      this.showForm = false;
    },
    doDisplaySkillForm: function() {
      this.showForm = true
    },

    initBalanceVue : function() {

      //console.log('BALANCE VUE initBalanceVue')

      this.balanceBuilder = this.$store.state.balanceBuilder;


      //console.log ('BALANCE VUE balance builder from store is :  ' + this.balanceBuilder)

      if (this.balanceBuilder) {

        let headersBase = [...this.balanceBuilder.levels];
        headersBase.unshift('');

        this.headers = headersBase.map((e, index) => {
          if (index === 0) {
            return {
              text: '-',
              align: 'start',
              sortable: false,
              value: 'action',
            }
          } else {
            return {
              text: 'Lv: ' + e,
              align: 'start',
              sortable: false,
              value: 'lvl' + e,
            }
          }

        });

        let actions = [];
        //actions.push(this.balanceBuilder.buildRowDropCoins());
        actions.push(this.balanceBuilder.buildCostHero());
        actions.push(this.balanceBuilder.buildCostSupport());
        actions.push(this.balanceBuilder.getRow(this.balanceBuilder.listUnlockedHeroes));
        this.actions = actions;

        let kpis = [];
        kpis.push(this.balanceBuilder.getRow(this.balanceBuilder.listKpiTotalGhost));
        this.actionsKpis = kpis;

        let diamonds = [];
        //diamonds.push(this.balanceBuilder.getRow(this.balanceBuilder.listDiamoandsAchievements));

        //TODO replace par le balance builder qui va farire la somme de tout.

        diamonds.push(this.balanceBuilder.getRow(this.balanceBuilder.buildAllDiamonds()));
        this.actionsDiamonds = diamonds;

        let diamondDetailList = [];
        console.log(KAchievement.UNLOCK_HEROES);
        // diamondDetailList.push(this.balanceBuilder.buildDiamondForAchievement(KAchievement.UNLOCK_SUPPORT));
        // diamondDetailList.push(this.balanceBuilder.buildDiamondForAchievement(KAchievement.UNLOCK_HEROES));
        // diamondDetailList.push(this.balanceBuilder.buildDiamondForAchievement(KAchievement.COLLECT_GOLD));
        // diamondDetailList.push(this.balanceBuilder.buildDiamondForAchievement(KAchievement.KILL_GHOST));
        diamondDetailList.push(this.balanceBuilder.listAchievementUnlockSupport);
        diamondDetailList.push(this.balanceBuilder.listAchievementUnlockHeroes);
        diamondDetailList.push(this.balanceBuilder.listAchievementCollectGold);
        diamondDetailList.push(this.balanceBuilder.listAchievementKillGhost);
        diamondDetailList.push(this.balanceBuilder.listAchievementReachHeroDps);
        diamondDetailList.push(this.balanceBuilder.listAchievmentKillBoss);
        diamondDetailList.push(this.balanceBuilder.listAchievmnentKillMiniBoss);
        diamondDetailList.push(this.balanceBuilder.listDiamondBonusFromHeroes);
        //diamondDetailList.push(this.balanceBuilder.getRow(this.balanceBuilder.buildDiamondForAchievement(KAchievement.KILL_)));

        this.diamondDetailList = diamondDetailList;

        let actionsSupport = [];
        actionsSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listAvailableMoneyForSupport));
        actionsSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listPossibleSupportUnlocked,true));

        this.actionsSupport = actionsSupport;


        let actionsResources = [];
        //actionsResources.push(this.balanceBuilder.getRow(this.balanceBuilder.listFarming,true));
        actionsResources.push(this.balanceBuilder.getRow(this.balanceBuilder.listBaseMoney));
        this.actionsResources = actionsResources;

        let actionsAllocations = [];
        actionsAllocations.push(this.balanceBuilder.listResourceRepartitionHeroLevelUp);
        actionsAllocations.push(this.balanceBuilder.listResourceRepartitionSupportLevelUp);
        this.actionsAllocations = actionsAllocations;

        let actionsLevelUp = [];
        actionsLevelUp.push(this.balanceBuilder.getRow(this.balanceBuilder.listAvailableMoneyForHeroLevelUp));
        actionsLevelUp.push(this.balanceBuilder.getRow(this.balanceBuilder.listPossibleLevelUp,true));
        actionsLevelUp.push(this.balanceBuilder.getRow(this.balanceBuilder.listPossibleLevelUpSupport));
        this.actionsLevelUp = actionsLevelUp;

        let supportStats = [];
        //supportLevelUp.push();
        //supportStats.push(this.balanceBuilder.getRow(this.balanceBuilder.supportSkillTapDMG,true));
        supportStats.push(this.balanceBuilder.getRow(this.balanceBuilder.listSupportStandardDPS));

        supportStats.push(this.balanceBuilder.getRow(this.balanceBuilder.listSupportDPSWithSkillDamageSupport))
        supportStats.push(this.balanceBuilder.getRow(this.balanceBuilder.listSupportDPSWithSkillSupportAllDamageOnly));
        supportStats.push(this.balanceBuilder.getRow(this.balanceBuilder.listSupportDPSWithSkillSupportAllDamageAndSupportDamage));


        this.supportLevelUp  = supportStats;

        let actionsGameplay = [];
        actionsGameplay.push(this.balanceBuilder.buildHPGhost());
        actionsGameplay.push(this.balanceBuilder.buildHPBoss());
        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.buildDmgHero2()));
        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listHeroDPSSupportSkillAllDamageAndTapDamage));


        actionsGameplay.push(this.balanceBuilder.listTapLevelUp);

        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listTotalTapToKillGhostForHeroImprovedBySupportSkill))

        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listTapSupportAttackBoosted));


        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listTimeSupportToKillGhost,true));

        // actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusHeroDpsSupportSkillAllDamageAndTapDamage,true))
        //

        //
        // actionsGameplay.push(this.balanceBuilder.getRow(
        //     this.balanceBuilder.listTotalTapToKillGhostForHeroImprovedBySupportSkill,true));

        /*

        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listSupportStandardDPS));

        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listSupportDPSDamageSupport));


        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listSupportDPSWithSkillSupportAllDamageOnly))

        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listSupportDPSWithSkillSupportAllDamageAndSupportDamage,true))

        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listHeroDPSSupportSkillHeroDamage,true));

        actionsGameplay.push(this.balanceBuilder.getRow(this.balanceBuilder.listHeroDPSSupportSkillAllDamage,true));




         */

        this.actionsGameplay = actionsGameplay;


        let diamandsAllocations = [];
        diamandsAllocations.push(this.balanceBuilder.listDiamondForAchievementMoney);

        this.diamondsAllocations = diamandsAllocations;

        let actionsPet = [];
        actionsPet.push(this.balanceBuilder.getRow(this.balanceBuilder.listTeamPets));
        actionsPet.push(this.balanceBuilder.getRow(this.balanceBuilder.listMaxLevelReachByPetTeam, 3));
        actionsPet.push(this.balanceBuilder.getRow(this.balanceBuilder.listPetBestActiveTapDamage,2));
        this.actionsPet = actionsPet;


        let actionsBonus = [];
        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusActivePet));
        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportTapDamage,'%'));
        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportAllDamage,'%'));
        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportSupportDamage,'%'));

        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportManaBonus,'per mn'));

        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportTotalDps,'%'));
        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSuppportWaveReduce,'monsters'));
        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportGoldDrop,'%'));

        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportCriticalChance,'%'));
        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportCriticalDamage,'%'));
        actionsBonus.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportCatBonus,'%'));


        this.actionsBonus = actionsBonus;


        let actionsBonusPassivePet = [];
        actionsBonusPassivePet.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusPetPassiveSkillAllDamage));
        actionsBonusPassivePet.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusPetPassiveSkillTapDamage));
        actionsBonusPassivePet.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusPetPassiveSkillSupporterDamage));
        actionsBonusPassivePet.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusPetPassiveSkillAllGold));





        this.actionsBonusPassivePet = actionsBonusPassivePet;


        /*
        skillType: EVOLVE

        skillType: TAP_DAMAGE
        skillType: ALL_DAMAGE
        skillType: SUPPORT_DAMAGE
        skillType: GOLD_DROP

        skillType: TAP_DAMAGE_TOTAL_DPS

        skillType: CAT_BONUS
        skillType: CRITICAL_DMG
        skillType: CRITICAL_CHANCE
        skillType: MANA BONUS
        skillType: WAVE_REDUCE
         */

        let actionsBonusSupport = [];
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillTapDamage));
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillAllDamage));
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillSupportDamage));
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillGoldDrop));

        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillTapDamageTotalDps));
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillCatBonus));
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillCriticalDamage));
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillCriticalChance));
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillManaBonus));
        actionsBonusSupport.push(this.balanceBuilder.getRow(this.balanceBuilder.listBonusSupportPassiveSkillWaveReduce));

        this.actionsBonusSupport = actionsBonusSupport;

      }



    },
  },

  props : ["pBalanceBuilder"],

  components: {
    //'supportData' : supportData,
    FooterLayout: FooterLayout,
    supportSkillViewer: supportSkillViewer,
    petListView:petListView,
    achievementListView:achievementListView,
    questListView: questListView,
    //playingTime:playingTime,
    diamondDetailView:diamondDetailView
  }
}

</script>
