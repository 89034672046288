<template>

    <v-card
            min-height="600"
            max-height="600"
    >

        <v-card-title>
            Detail Diamonds Gains

        </v-card-title>



        <v-card
                max-height="700" class="overflow-x-auto"
        >


                        <v-data-table

                                :headers="pHeaders"
                                :items="pDiamondList"

                                class="elevation-1 petList"
                                hide-default-footer
                                :options="{itemsPerPage:500}"
                        >

                        </v-data-table>







        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="dispatchClose()">Close</v-btn>
        </v-card-actions>
    </v-card>



</template>





<style lang="scss">
    .petList tbody {
        tr .standard:hover {
            background-color: darkgray !important;
        }
    }

    .standard {
        background-color: transparent;
    }

    .selected {
        background-color: dodgerblue !important;
    }

</style>

<script>

    // import lodash from 'lodash';
    // import GameplayAchievement from '../GameplayAchievement';
    // import AchievementConstant from '../constants/AchievementIdConstants';

    export default {
        data() {
            return {
                showDynamic: true,
                selectedRow: null,
                search: "",
                diamondsList: [],
                showDetail: false,
                cumulatedReward: 0,
            }
        },

        props: {
            'pDiamondList': {
                type: Array,
                required: true
            },
            'pHeaders' : {
                type: Array,
                required: true
            },
            'shouldInit': {
                type: Boolean,
            }
        },

        methods: {


            getSelected: function (index) {
                return index === this.selectedRow;
            },

            didClickOnRow: function (index) {
                //console.log('did click on row');
                //console.log(index);
                this.selectedRow = index;
            },

            loadData: function (list) {
                this.diamondsList = [];
                if (Array.isArray(list)) {
                    list.forEach(e => {
                        //console.log('load data')
                        console.log(e);
                        //let copy = deepCopy(e);

                        //this.questList.push(Object.assign({}, e));

                        //let achievementModel = new Achievment(e);
                        //let achievement = new GameplayAchievement(e);

                        //this.achievementList.push(lodash.cloneDeep(achievement));
                        //this.questList.push(copy);
                    });
                }
            },

            save : function(e) {
                //console.log(e)
                return e;
            },


            didChange: function () {
                //console.log('DID CHANGE did change something in AchievementList');
                //this.loadData(this.pDiamondsList);
            },

            dispatchClose: function () {
                this.$emit('close');
            },

            close : function() {
                this.showDetail = false;
            }


        },

        created: function () {
            this.loadData(this.pDiamondsList);
        },

        components: {},

        watch: {
            pDiamondsList: {
                deep: true,
                handler: 'didChange'
            },



            shouldInit: {
                handler: 'didChange'
            }
        },

    }

</script>
