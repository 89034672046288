<template>
  <BalanceContainer :key="this.$store.state.balanceBuilderVersion" :pBalanceBuilder = "this.pBalanceBuilder"> </BalanceContainer>
</template>

<script>

import BalanceContainer from "./BalanceContainer";


export default {

  created : async function() {
    //console.log('created balance');
  },

  props : ["pBalanceBuilder"],
  components : {
    BalanceContainer: BalanceContainer
  }

}

</script>
