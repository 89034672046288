<template>

    <v-card
            min-height="600"
            max-height="600"
    >
        <v-card-title>
            Pet List - {{this.petList.length}} - will need : - {{this.petDiamonds}} diamonds

        </v-card-title>

        <v-dialog
                v-model="showDetail"
                max-width="900px"
                scrollable
                @click:outside="close()"
        >
            <v-card>
                <v-card-title>{{this.$get(selectedPet,'name')}} </v-card-title>
                <v-data-table

                        :headers="headersProgressionDetail"
                        :items="listPetProgression"

                        class="elevation-1 petList"

                        hide-default-footer

                        :options="{itemsPerPage:200}"
                        :search="search"
                        show-group-by

                >

                    <template v-slot:item.tap="{item}">
                        {{ item.tap }} hits
                    </template>

                    <template v-slot:item.regeneration="{item}">
                            {{ item.regeneration / 60 }} mn
                    </template>

                    <template v-slot:item.damage="{item}">
                        {{ item.damage }} %
                    </template>

                    <template v-slot:item.skillValue="{item}">
                        {{ item.skillValue }} %
                    </template>

                    <template v-slot:item.passiveSkillValue="{item}">
                        {{ item.passiveSkillValue }} %
                    </template>







                </v-data-table>
            </v-card>
        </v-dialog>


        <v-text-field
                type="number"
                v-model.number="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
        ></v-text-field>

        <v-card
                max-height="400" class="overflow-y-auto"
        >

            <v-container>


                <v-row>
                    <v-col>
                        <v-data-table

                                :headers="headers"
                                :items="petList"
                                class="elevation-1 petList"

                                hide-default-footer

                                :options="{itemsPerPage:200}"
                                :search="search"
                                show-group-by
                                @click:row="didClickOnRow"
                        >

                            <template v-slot:item.petBaseDamage="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.petBaseDamage"
                                >

                                    {{ item.petBaseDamage }}


                                    <template v-slot:input>
                                        <v-text-field
                                                type="number"
                                                v-model.number="item.petBaseDamage"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                        ></v-text-field>


                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.petDamIncPerLvT1="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.petDamIncPerLvT1"
                                >

                                    {{ item.petDamIncPerLvT1 }}


                                    <template v-slot:input>
                                        <v-text-field
                                                type="number"
                                                v-model.number="item.petDamIncPerLvT1"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                        ></v-text-field>


                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.petDamIncPerLvT2="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.petDamIncPerLvT2"
                                >

                                    {{ item.petDamIncPerLvT2 }}


                                    <template v-slot:input>
                                        <v-text-field
                                                type="number"
                                                v-model.number="item.petDamIncPerLvT2"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                        ></v-text-field>


                                    </template>
                                </v-edit-dialog>
                            </template>


                            <template v-slot:item.petDamIncPerLvT3="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.petDamIncPerLvT3"
                                >

                                    {{ item.petDamIncPerLvT3 }}


                                    <template v-slot:input>
                                        <v-text-field
                                                type="number"
                                                v-model.number="item.petDamIncPerLvT3"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                        ></v-text-field>


                                    </template>
                                </v-edit-dialog>
                            </template>


                            <template v-slot:item.petBonusType="{item}">

                                <v-edit-dialog
                                        :return-value.sync="item.petBonusType"
                                >

                                    {{ item.petBonusType }}


                                    <template v-slot:input>
                                        <!--                                        <v-text-field-->
                                        <!--                                                v-model="item.petBonusType"-->
                                        <!--                                                label="Edit"-->
                                        <!--                                                single-line-->
                                        <!--                                                autofocus-->
                                        <!--                                                @focus="$event.target.select()"-->

                                        <!--                                        ></v-text-field>-->

                                        <v-select

                                                v-model="item.petBonusType"
                                                :items="petBonusTypeSelect"
                                                item-text="text"
                                                item-value="value"
                                                label="Select"
                                                single-line
                                        >


                                        </v-select>

                                    </template>
                                </v-edit-dialog>

                            </template>


                            <template v-slot:item.petBoIncPerLv="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.petBoIncPerLv"
                                >

                                    {{ item.petBoIncPerLv }}


                                    <template v-slot:input>
                                        <v-text-field
                                                type="number"
                                                v-model.number="item.petBoIncPerLv"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                        ></v-text-field>


                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.petBaseBonus="{item}">
                                <v-edit-dialog
                                        :return-value.sync="item.petBaseBonus"
                                >

                                    {{ item.petBaseBonus }}


                                    <template v-slot:input>
                                        <v-text-field
                                                type="number"
                                                v-model.number="item.petBaseBonus"
                                                label="Edit"
                                                single-line
                                                autofocus
                                                @focus="$event.target.select()"

                                        ></v-text-field>


                                    </template>
                                </v-edit-dialog>
                            </template>
                        </v-data-table>

                    </v-col>
                </v-row>
            </v-container>


        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn color="blue darken-1" text @click="dispatchClose()">Close</v-btn>
            <v-btn color="green darken-1" text @click="dispatchSave()">Save</v-btn>
        </v-card-actions>

    </v-card>


</template>

<style lang="scss">
    .petList tbody {
        tr .standard:hover {
            background-color: darkgray !important;
        }
    }

    .standard {
        background-color: transparent;
    }

    .selected {
        background-color: dodgerblue !important;
    }

</style>

<script>

    import lodash from 'lodash'

    import SMPGameplay from "../SMPGameplay";

    // "petBaseDamage":300,
    //     "petDamIncPerLvT1":170,
    //     "petDamIncPerLvT2":140,
    //     "petDamIncPerLvT3":30,
    //     "petBonusType":1,
    //     "petBaseBonus":100,
    //     "petBoIncPerLv":10,

    // const ALL_DAMAGE = 1;
    // const ALL_SUPPORTER_DAMAGE =2 //use for supports TAP_DAMAGE,
    // const ALL_GOLD =  3;

    export default {
        data() {
            return {
                showDynamic: true,
                selectedPet: null,

                showDetail: false,
                petDiamonds : 0,
                petBonusTypeSelect: [{text: 'ALL_DAMAGE', value: 1}, {
                    text: 'ALL_SUPPORTER_DAMAGE',
                    value: 2
                }, {text: 'TAP_DAMAGE', value: 3}, {text: 'ALL_GOLD', value: 4}],
                search: "",
                petList: [],
                listPetProgression : [],
                headers: [
                    {text: 'Pet Name', value: 'petName', groupable: false},

                    {text: 'Pet Base Damage', value: 'petBaseDamage', groupable: false},
                    {text: 'Pet T1', value: 'petDamIncPerLvT1', groupable: false},
                    {text: 'Pet T2', value: 'petDamIncPerLvT2', groupable: false},
                    {text: 'Pet T3', value: 'petDamIncPerLvT3', groupable: false},
                    {text: 'Pet bonus type', value: 'petBonusType', groupable: false},
                    {text: ' Bonus type', value: 'petBonusTypeFullName',},
                    {text: 'Inc Per Lv', value: 'petBoIncPerLv', groupable: false},
                    {text: 'Base Bonus', value: 'petBaseBonus', groupable: false},

                ],
                headersDetail: [
                    {text: 'Level', value: 'level', groupable: false},
                    {text: 'Challenge', value: 'challenge', groupable: false},
                    {text: 'Reward', value: 'reward', groupable: false}
                ],

                headersProgressionDetail: [
                    {text: 'Level', value: 'level', groupable: false},
                    {text: 'Tap Capacity', value: 'tap', groupable: false},
                    {text: 'Base Damage', value: 'damage', groupable: false},
                    {text: 'Active Skill Value', value: 'skillValue', groupable: false},
                    {text: 'Passive Skill Value', value: 'passiveSkillValue', groupable: false},
                    {text: 'Regeneration Time', value: 'regeneration', groupable: false},
                ]
            }
        },

        props: {
            'pPetList': {
                type: Array,
                required: true
            },
            'shouldInit': {
                type: Boolean,
            },
            'pBalance' : {
                type: Object
            }
        },

        methods: {


            getSelected: function (index) {
                return index === this.selectedPet;
            },

            didClickOnRow: function (pet) {
                //console.log('didClickOnRow');
                //console.log(pet);
                this.selectedPet = pet;


                //let tempList = this.pBalance.buildPetProgression(pet.petID);
                let tempList = this.$store.state.balanceBuilder.
                buildPetProgression(pet.petID);
                this.listPetProgression = tempList[pet.petID];

                this.headersProgressionDetail[3].text = pet.petBonusTypeFullName + " (active)";

                this.headersProgressionDetail[4].text = pet.petBonusTypeFullName+ " (passive)";


                //this.selectedRow = item;
                this.showDetail = true;

                //console.log('finished did click on row');

            },
            close : function() {
                this.showDetail = false;
            },

            loadData: function (list) {
                this.petList = [];
                if (Array.isArray(list)) {
                    list.forEach(e => {
                        //console.log('load data')
                        //let copy = deepCopy(e);

                        //this.questList.push(Object.assign({}, e));
                        this.petList.push(lodash.cloneDeep(e));
                        //this.questList.push(copy);
                    });
                }

                this.petDiamonds = SMPGameplay.GetDiamondCostForLevelUpPet(39,99);
            },


            didChange: function () {
                //console.log('did change something in petListData');
                this.loadData(this.pPetList);
            },

            dispatchClose: function () {
                this.$emit('close');
            },

            dispatchSave: async function () {
                //this.questList;
                //this.$store.commit('updateSkillList',this.skillList);
                let res = await this.$store.commit('pushPets',this.petList);
                console.log('did push pets ' + res);

                //await this.$store.commit('initBalance');
                //console.log('finish updating balance');

                //this.pBalance.refreshPetProgression();
            },

            didUpdatePetList: function (petList) {
                //console.log('did update pet list');
                //console.log(petList);

                const ALL_DAMAGE = 1;
                const ALL_SUPPORTER_DAMAGE = 2 //use for supports TAP_DAMAGE,
                const TAP_DAMAGE = 3;
                const ALL_GOLD = 4;

                petList.forEach(pet => {
                    switch (pet.petBonusType) {
                        case ALL_DAMAGE:
                            pet.petBonusTypeFullName = 'ALL_DAMAGE';
                            break;
                        case ALL_SUPPORTER_DAMAGE:
                            pet.petBonusTypeFullName = 'ALL_SUPPORTER_DAMAGE';
                            break;
                        case TAP_DAMAGE:
                            pet.petBonusTypeFullName = 'TAP_DAMAGE';
                            break;
                        case ALL_GOLD:
                            pet.petBonusTypeFullName = 'ALL_GOLD';
                            break;
                    }

                })


            }


        },

        created: function () {
            this.loadData(this.pPetList);
        },

        components: {},

        watch: {
            pPetList: {
                deep: true,
                handler: 'didChange'
            },

            shouldInit: {
                handler: 'didChange'
            },

            petList: {
                deep: true,
                handler: 'didUpdatePetList'
            }
        },

    }

</script>
